//create store
import { createStore } from 'vuex'

//vuex persist
import vuexPersistance from 'vuex-persist';

const storage = createStore({
    state: {
        isAuth: false,
        user: [],
        token: ''
    },
    mutations: {
        setAuth(state, status) {
            state.isAuth = status
        },
        setUserData(state, data) {
            state.user = data
        },
        setToken(state, data) {
            state.token = data
        },
        logOut(state) {
            state.isAuth =false
            state.user = []
            state.token = ''
        },
    },
    plugins:[
        new vuexPersistance({
            storage: window.localStorage
        }).plugin
    ]
})

export default storage