// axios
import axios from 'axios'
import router from './router';
import storage from './storage';

const server = 'https://admin.enelcine.com.ar/api'

export default {
    checkAuth: async function () {
        if( storage.state.token ){

          await axios.get(
            server+'/users/me?access_token='+storage.state.token,
          )
          .catch(error => {
            if (error.response) {
              if(error.response.status != 200){
                storage.commit('logout')
                location.replace('/login')
              }
            }
          })
        }else{
          router.push('/login')
        }
      },
      logOut(){
        storage.commit('logOut')
        location.replace('/login')
      }
}