<template>
  <ion-app>
      <ion-router-outlet mode="ios" animated="false"></ion-router-outlet>
  </ion-app>
</template>

<script lang="ts">
import {
  IonApp,
  IonRouterOutlet
} from '@ionic/vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet, 
  }
});
</script>

<style>
*{
  font-family: 'Poppins', sans-serif;
}

html{
  height: 100vh;
}

a{
  text-decoration: none;
}

/*-- MENU --*/
ion-split-pane{
  --side-max-width: 300px;
}

#menu ion-item.profile{
  --min-height: 80px;
}

/*-- HEADER --*/
ion-toolbar{
  --min-height: 60px;
}

ion-toolbar ion-button.ion-no-padding{
  padding: 0 8px;
}

ion-header .logo{
  height: 36px;
  width: auto;
}

ion-back-button{
  --padding-start: 8px
}

/*-- CONTENT --*/
ion-content{
  height: -webkit-fill-available;
}

.page-title{
  margin-left: 2.5%;
}

.page-title ion-icon{
  background: var(--ion-color-primary, #3880ff);
  padding: 8px;
  margin-right: 16px;
  border-radius: 100%;
}

section{
  margin-top: 20px;
  margin-bottom: 40px;
}

section:last-child{
  margin-bottom: 0px;
}

.section-header{
  display: flex;
  align-items: center;
}

.swiper-navigation{
  display: flex;
}

.container{
  padding: 0 2.5%;
}

ion-content{
  --background: #f7fafc;
  /*--padding-top: 20px;*/
  --padding-bottom: 20px;
}

ion-card{
  padding: 8px;
  margin: 0 0 12px 0;
}

ion-card span{
  font-size: 14px;
}

ion-row{
  margin: 0 -8px;
}

ion-col{
  padding: 8px;
  margin-bottom: 0 !important;
}

.logo{
  height: 53px;
}

.loading{
    position: relative;
    overflow: hidden;
    background: #cfcfcf !important;
    animation: loading 1s infinite !important;
}

.loading *{
    display: none;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50%   {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.section-title{
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  padding-left: 16px;
  margin-bottom: 10px;
  align-items: center;
  gap: 16px;
}

.section-title::before{
  content: "";
  display: block;
  width: 6px;
  height: 24px;
  background-color: #fcaf17;
  margin: 6px 0 6px -16px;
  border-radius: 4px;
}

/*.section-title::after{
  content: "";
  display: block;
  width: 116px;
  height: 6px;
  background-color: #fcaf17;
  margin-top: 8px;
  margin-bottom: 24px;
}*/

.cover{
  width: 100%;
  padding-top: 35%;
  background: black;
  position: relative;
  overflow: hidden;
}

.cover .background-image{
  background-size: 200%;
  background-position: top left;
  filter: blur(200px);
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
}

.cover .main-image{
  padding-right: 60%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 50%);
}

.cover .text{
  position: absolute;
  top: 50%;
  left: 32px;
  transform: translateY(-50%);
  max-width: 40%;
}

.cover .text h2{
  color: white;
  font-weight: 700;
  font-size: 36px;
  margin: 32px 0;
  text-shadow: 1px 1px 0 black;
}

@media (max-width: 768px){
  ion-header{
    display: block;
  }

  .section-title{
      font-size: 18px;
  }

  .container{
    padding: 0 5%;
  }

  ion-col{
    margin-bottom: 16px;
    padding: 4px;
  }

  .cover{
    padding-top: 0;
    height: 300px;
  }

  .cover .main-image{
    padding-right: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 50%);
  }

  .cover .text{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    top: initial;
    bottom: 0;
    left: 0;
    transform: initial;
    max-width: initial;
    width: 100%;
    padding:16px;
  }

  .cover .text h2{
    font-size: 24px;
    margin: 16px 0;
  }
}

</style>