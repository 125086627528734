<template>
  <ion-page ref="login">
    <!-- HEADER -->
    <ion-header>
    </ion-header>

    <!-- CONTENT -->
    <ion-content :fullscreen="true">
      <div class="login-body">
        <div class="login">
          <div class="login-form">
              <ion-img src="/assets/logo.svg" class="logo"></ion-img>
              <form @submit.prevent="submit" @keyup.enter="submit">
                <ion-item-group>
                  <ion-item>
                      <ion-label color="medium" position="stacked">Usuario</ion-label>
                      <input type="email" ref="email" color="light">
                  </ion-item>
                </ion-item-group>  
                <ion-item-group>
                  <ion-item>
                      <ion-label color="medium" position="stacked">Contraseña</ion-label>
                      <ion-button size="small" slot="end" fill="clear" class="ion-align-self-end" @click="showPass">
                        <ion-icon :icon="passIcon" color="light"></ion-icon>
                      </ion-button>
                      <input type="password" ref="password" color="light">
                  </ion-item>
                </ion-item-group>  
                <ion-button type="submit" class="login-button" size="block">
                  <ion-text color="dark" v-if="loginStatus == false">Iniciar Sesión</ion-text>
                  <ion-spinner v-else name="crescent" color="dark"></ion-spinner>
                </ion-button>
              </form>
          </div>
        </div>
        <div class="background" :style="(background) ? { backgroundImage: 'url(' + background + ')' } : null">
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import router from '../router';

import{
  IonPage,
  IonHeader,
  IonContent,
  toastController,
  IonButton,
  IonText,
  IonSpinner,
  IonImg,
  IonLabel,
  IonItem,
  IonItemGroup,
  IonIcon,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Login',
  components:{
    IonPage,
    IonHeader,
    IonContent,
    IonButton,
    IonText,
    IonSpinner,
    IonImg,
    IonLabel,
    IonItem,
    IonItemGroup,
    IonIcon
  },
  setup(){
    return{
      IonPage,
      IonHeader,
      IonContent,
      eyeOutline,
      eyeOffOutline
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      background: String,
      loginStatus: false,
      passIcon: eyeOutline,
      passShowStatus: false
    }
  },
  mounted(){
    this.loadPage()
  },
  methods:{
    loadPage: async function(){
      localStorage.removeItem('token')
      const response = await this.axios.get(this.server+'/')
      this.background = response.data.data.api.project_background.full_url
    },
    showPass: function(){
      if( this.passShowStatus === false ){
        this.passShowStatus= true
        this.$refs.password.type= 'string'
        this.passIcon= eyeOffOutline
      }else{
        this.passShowStatus= false
        this.$refs.password.type= 'password'
        this.passIcon= eyeOutline
      }
    },
    submit: function(){
      const email = this.$refs.email.value
      const password = this.$refs.password.value

      if( email == '' || password == '' ){
        this.openToast()
      }else{
        this.$refs.email.readonly= true
        this.$refs.password.readonly= true
        this.auth(email,password)
      }
    },
    auth: async function(email, password){
      this.loginStatus = true

      const endpoint= this.server+'/auth/authenticate'
      const response= await this.axios.post(endpoint, {'email': email, 'password': password}) 
      
      if( response.status == 200 ){
        this.$store.commit('setAuth', true)
        this.$store.commit('setUserData', response.data.data.user)
        this.$store.commit('setToken', response.data.data.token)
        router.push('/inicio')
        this.loginStatus = false
      }
    },
    openToast: async function() {
      const toast = await toastController
        .create({
          message: 'Hay datos incompletos',
          color: 'danger',
          duration: 2000
        })
      return toast.present();
    }
  }
})
</script>

<style scoped>
.hidden{
  display: none;
}

ion-content{
  --padding-bottom: 0;
}

.login-body{
  display: flex;
  width: 100%;
  height: 100%;
}

.background{
  width: 100%;
  height: 100%;
  background-color: #cfcfcf;
  background-size: cover;
  background-position: center;
}

.login{
  padding:2rem;
  height: 100%;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .login-form .logo{
    margin-bottom: 32px;
}

.login .login-form{
    min-width: 360px;
}

.login, .login-form, .login-form ion-item{
  --background: #212121;
  background: #212121;
}

.login-form ion-item-group{
  --color: white;
  margin-bottom: 32px;
}

.login ion-item{
    --padding-start: 0px;
    --inner-padding-end: 0px;
    --border-color: #666;
}

input{
  background: none;
  color: white;
  border: 0;
  outline: none;
}

.login .login-button{
    margin-top: 32px;
}

ion-button{
  --padding-end: 0;
}

@media (max-width: 768px){
  .login{
    width: 100%;
  }

  .login .login-form{
    min-width: 240px;
  }
}
</style>