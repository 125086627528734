import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Page from '../views/Page.vue'
import Login from '../views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/inicio'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Page',
    component: Page,
    children: [
      {
        path: '/inicio',
        name: 'Inicio',
        component: () => import('@/views/pages/Inicio.vue')
      },
      {
        path: '/materiales',
        name: 'Materiales',
        component: () => import('@/views/pages/Materiales.vue')
      },
      {
        path: '/materiales/:slug',
        name: 'Pelicula',
        component: () => import('@/views/singles/Pelicula.vue')
      },
      {
        path: '/competitive',
        component: () => import('@/views/pages/Competitive.vue')
      },
      {
        path: '/notificaciones',
        component: () => import('@/views/pages/Notificaciones.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
