
import router from '../router';

//utilities
import utilities from '../utiities'

import {
  IonPage,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonImg,
  IonLabel,
  IonMenuToggle,
  IonMenu,
  IonSplitPane,
  IonContent,
  IonButton,
  IonList,
  IonItem,
  IonIcon,
  IonText,
  IonFooter
  } from '@ionic/vue';

import { homeOutline, downloadOutline, calendarOutline, repeatOutline, logOutOutline, personCircleOutline } from 'ionicons/icons';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonPage,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonImg,
    IonLabel,
    IonMenuToggle,
    IonMenu, 
    IonSplitPane,
    IonContent,
    IonButton,
    IonList,
    IonItem, 
    IonIcon,
    IonText,
    IonFooter
  },
  setup(){
    return{
      homeOutline,
      downloadOutline,
      calendarOutline,
      repeatOutline,
      logOutOutline,
      personCircleOutline
    } 
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      menuStatus: false,
      AnimationStatus: false,
      userDara: [],
      checkAuth: Function,
      menu:[
        {
          text: 'Inicio',
          to: '/'
        },
        {
          text: 'Materiales',
          to: '/materiales'
        },
        {
          text: 'Competitive',
          to: '/competitive'
        },
        {
          text: 'Notificaciones',
          to: '/notificaciones'
        }
      ]
    }
  },
  methods: {
    logOut: function(){
      utilities.logOut()
     }
  }
})
