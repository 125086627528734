<template>
  <ion-page>

      <ion-split-pane content-id="main">
      
        <!--  Menu  -->
        <ion-menu content-id="main">
          <ion-header>
            <ion-toolbar color="dark">
              <ion-title>
                <ion-img src="/assets/logo.svg" class="logo"></ion-img>
              </ion-title>
            </ion-toolbar>
          </ion-header>

          <!--  Content  -->
          <ion-content color="dark" class="ion-padding">
            <ion-list>
              <ion-menu-toggle auto-hide="false">
                <router-link v-for="(item,index) in menu" :to="item.to" :key="index">
                  <ion-item color="dark" lines="none">
                      <ion-label><ion-text color="primary"><b>{{item.text}}</b></ion-text></ion-label> 
                  </ion-item>
                </router-link>
              </ion-menu-toggle>
            </ion-list>
          </ion-content>

          <ion-footer class="footer" color="contrast">
            <ion-item class="profile ion-no-padding" lines="none">
              <ion-icon slot="end" size="large" :icon="personCircleOutline" color="primary"></ion-icon>
              <ion-label>
                <ion-text color="primary">
                  <h2>{{ $store.state.user.first_name }} {{ $store.state.user.last_name }}</h2>
                  <h3>{{ $store.state.user.email }}</h3>
                </ion-text>
              </ion-label>
            </ion-item>

            <ion-button size="block" @click="logOut()">
              <ion-text color="dark">
                Cerrar Sesión
              </ion-text>
              <ion-icon :icon="logOutOutline" slot="end" color="dark"></ion-icon>
            </ion-button>
          </ion-footer>

        </ion-menu>

        <!-- the main content -->
        <ion-router-outlet id="main" swipeGesture="false" mode="ios"></ion-router-outlet>
      </ion-split-pane>

  </ion-page>
</template>

<script lang="ts">
import router from '../router';

//utilities
import utilities from '../utiities'

import {
  IonPage,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonImg,
  IonLabel,
  IonMenuToggle,
  IonMenu,
  IonSplitPane,
  IonContent,
  IonButton,
  IonList,
  IonItem,
  IonIcon,
  IonText,
  IonFooter
  } from '@ionic/vue';

import { homeOutline, downloadOutline, calendarOutline, repeatOutline, logOutOutline, personCircleOutline } from 'ionicons/icons';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonPage,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonImg,
    IonLabel,
    IonMenuToggle,
    IonMenu, 
    IonSplitPane,
    IonContent,
    IonButton,
    IonList,
    IonItem, 
    IonIcon,
    IonText,
    IonFooter
  },
  setup(){
    return{
      homeOutline,
      downloadOutline,
      calendarOutline,
      repeatOutline,
      logOutOutline,
      personCircleOutline
    } 
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      menuStatus: false,
      AnimationStatus: false,
      userDara: [],
      checkAuth: Function,
      menu:[
        {
          text: 'Inicio',
          to: '/'
        },
        {
          text: 'Materiales',
          to: '/materiales'
        },
        {
          text: 'Competitive',
          to: '/competitive'
        },
        {
          text: 'Notificaciones',
          to: '/notificaciones'
        }
      ]
    }
  },
  methods: {
    logOut: function(){
      utilities.logOut()
     }
  }
})
</script>

<style scoped>
/*-- MENU --*/
ion-split-pane{
  --side-max-width: 300px;
}

.split-pane-visible > .split-pane-side {
    border-right: 1px solid #666 !important;
}

ion-menu ion-item.profile{
  --min-height: 80px;
  --background: /*#222428*/ #000000;
}

ion-menu ion-item.profile ion-icon{
  margin: auto 0;
}

ion-menu ion-list ion-item{
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 0;
  border-bottom: 0.5px solid #666;
}

ion-menu ion-footer {
  padding: 16px;
  background: #000;
  display: flex;
  flex-direction: column;
}

ion-menu ion-footer .logo{
  margin-bottom: 16px;
  flex: 1;
}

@media (max-width: 768px){
  ion-menu *{
    font-size: 15px;
  }
}
</style>